module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/website-blog/website-blog/node_modules/gatsby-remark-images","id":"86adead2-2c96-5e57-894f-7389e7adc6bd","name":"gatsby-remark-images","version":"6.24.0","modulePath":"/home/runner/work/website-blog/website-blog/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"disableBgImage":true,"showCaptions":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/website-blog/website-blog/node_modules/gatsby-remark-embed-gist","id":"47f9f0fe-204b-56b1-93b3-56852ca46775","name":"gatsby-remark-embed-gist","version":"1.2.1","modulePath":"/home/runner/work/website-blog/website-blog/node_modules/gatsby-remark-embed-gist/index.js","pluginOptions":{"plugins":[],"username":"plourenco"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":["onRenderBody"]},{"resolve":"/home/runner/work/website-blog/website-blog/node_modules/gatsby-remark-autolink-headers","id":"b1aaa934-ac39-5fee-b9c4-125f2997a5b5","name":"gatsby-remark-autolink-headers","version":"5.24.0","modulePath":"/home/runner/work/website-blog/website-blog/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/website-blog/website-blog","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"disableBgImage":true,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-22CTK6K8MP"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
